import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, navigate } from "gatsby"

import useDeviceDetect from "../service/useDeviceDetect"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import Breadcrumbs from "../components/addon/breadcrumbs"
import NewsLetter from "../components/addon/newsLetter"
import Gallery from "../components/pageSections/gallery"
import EventsListingSection from "../components/pageSections/eventsListing"
import VideoModal from "../components/addon/videoModal"
import CustomLink from '../components/addon/Link'

import useScript from '../service/useScript';
import EVENT_API from '../api/events'
import { compareDates, convertArrToStr, formatEventDate, formatEventRegDate, getBannerVideoThumbnail, getYouTubeId } from "../service/helper"

const EventPage = (props) => {
    const { data } = props
    useScript("/assets/js/custom_select.js");
    useScript("/assets/js/eventDetail.js");
    const { isMobile } = useDeviceDetect()
    const defaultImageSrc = isMobile === true ? "/assets/images/placeholder-348x233.png" : "/assets/images/placeholder-635x233.png"
    const [params, setParams] = useState({})
    const [eventId, setEventId] = useState(null)
    const [showRegBtn, setShowRegBtn] = useState(false)
    let pageInfo = {}
    let metaTags = []
    let schema = []
    let [pageLimit] = useState(3)
    let [queryParams] = useState({
    'limit': pageLimit,
    'offset': 0
    })
    const [videoURL, setVideoURL] = useState("")
    const [showModal, setShowModal] = useState(false)
    let imageSrc = ""
    let altText = ""
    let info = {}

    info = data?.allNodeEvent?.nodes[0]
    imageSrc = isMobile === true ? info?.relationships?.image_mobile?.uri?.url : info?.relationships?.image?.uri?.url
    altText = info?.field_image?.alt || "Hinduja Hospital"

    if (data && data?.allNodeEvent && data?.allNodeEvent?.nodes) { 
        const tags = info?.metatags ? info.metatags : []
        pageInfo = info
        metaTags = tags
        schema = info.relationships?.field_seo_schema ? info.relationships?.field_seo_schema : []
    }
    
    useEffect(() => {
        if (data && data.allNodeEvent && data.allNodeEvent.nodes) {
            if(info?.drupal_internal__nid) setEventId(info?.drupal_internal__nid)
            let json = {
                limit: 3,
                offset: 0,
                when: "future"
            }
            if (info.field_event_type) {
                json['type'] = info.field_event_type
            }
            setParams(json)
        }
    }, [])

    const getVideoImage = () => {
    const url = getBannerVideoThumbnail(pageInfo.video.url)
    if (url) {
        return url
    }
        return null
    }
    const playVideo = () => {
    const url =data.allNodeEvent.nodes[0].video.url ;
    setShowModal(true)
        if (url) {     
            setVideoURL(url)
        }
    }
    const closeModal = () => {
        setShowModal(false)
    }       

    useEffect(() => {
        // getData()
    }, [queryParams])

    const getEventDetails = () => {
        EVENT_API.getEventsDetails(eventId)
        .then(res => {
            if(res?.current_date_time && res?.registrationDate) {
                let result = compareDates(res?.current_date_time, res?.registrationDate)
                if(result) {
                    setShowRegBtn(true)
                } else {
                    setShowRegBtn(false)
                }
            } 
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if(eventId) {
            getEventDetails()
        }
       
    },[eventId])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [pageInfo?.relationships?.gallery, pageInfo])

return(
<Layout>
    <Meta
    files={
    {
    js: [],
    css: [
    "/assets/css/blog.css",
    '/assets/css/accessibility.css',
    '/assets/css/common.css',
    '/assets/css/slick-theme.css',
    '/assets/css/slick.css'
    ]
    }
    }
    tags={metaTags}
    />
    <Schema schema={schema} />
    <main className="innerpage">
        {pageInfo?.relationships?.field_breadcrumb_events &&
            <section className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul>
                                {pageInfo?.relationships?.field_breadcrumb_events?.field_breadcrumb_link?.map((el, index) => {
                                            return  (
                                            <li key={index}>
                                                <CustomLink data={{link : el}}>{el.title}</CustomLink>
                                            </li>
                                        )
                                    })
                                }
                                <li><span>{pageInfo?.relationships?.field_breadcrumb_events?.field_title}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        }
        <section className="event_intro_latest section-bg section-py">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="video_container">
                            { pageInfo?.video?.url ?
                            <>
                            <img  src={getVideoImage() || defaultImageSrc} alt={pageInfo.title || ''} className="vid_img " />
                            <button className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="yXG2BeAYVGs" onClick={playVideo} >
                            <img src="/assets/images/icons/video_icon_white.svg" alt="white-play-icon" />
                            </button>
                            </>
                            :
                            <>
                            {
                            imageSrc ?
                            <img
                                src={imageSrc}
                                alt={altText}
                                />
                            :
                            <img
                                src={defaultImageSrc}
                                alt={altText} />
                            }
                            </>
                            }
                            <span className="overlay">
                            <img src="" alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="event_desc_main">
                            <div className="evt_category">CATEGORY:&nbsp;
                                { pageInfo?.relationships?.category?.length &&
                                <span>
                                { convertArrToStr(pageInfo.relationships.category) }
                                </span>
                                }
                            </div>
                            <h1 className="evt_title">
                                {pageInfo.title || ''}
                            </h1>
                            {
                            pageInfo?.relationships?.brochure?.uri?.url &&
                            <div className="link_wrap">
                                <a href={pageInfo.relationships.brochure.uri?.url} download>Download Brochure</a>
                            </div>
                            }
                            <div className="evt_time">
                                <img src="/assets/images/icons/icon_event_calendar.svg" alt="" className="icon" />
                                {pageInfo?.eventDate && formatEventDate(pageInfo.eventDate)}
                                {pageInfo?.eventEndDate && ' - ' + formatEventDate(pageInfo.eventEndDate)}
                                <br />
                                {
                                pageInfo?.registrationDeadline && showRegBtn &&
                                <>Registration Close On: {formatEventRegDate(pageInfo.registrationDeadline)}</>
                                }
                            </div>
                            {
                            pageInfo?.blurb &&
                            <div className="evt_small_desc">
                                {pageInfo.blurb}
                            </div>
                            }
                            {
                            pageInfo?.recordedSession?.uri &&
                            <div className="btn_list">
                                <a
                                    href={pageInfo.recordedSession.uri} target="_blank"
                                    className="btn btn-primary-solid">
                                View Recorded Session</a>
                            </div>
                            }
                            {
                            pageInfo?.relationships?.schedule?.uri?.url &&
                            <a 
                                href={pageInfo.relationships.schedule.uri?.url}
                                className="mt-4 btn btn-link"
                                download>
                            Download program schedule</a>
                            }
                            {showRegBtn &&
                            <div className="mt-2">
                              <a className="btn btn-primary-solid"
                                onClick={() => navigate(`/events-registration/?id=${pageInfo?.drupal_internal__nid}`)}>
                                  Register Now
                              </a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="accordion_container event_detail_container">
                    <div id="accordion" role="tablist" className="common_accordion">
                        {
                        pageInfo?.overview?.processed &&
                        <div className="card">
                            <div className="card-header" role="tab" id="overview_heading">
                                <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#evtOverview" aria-expanded="false" aria-controls="evtOverview">
                                    Overview
                                    </a>
                                </h5>
                            </div>
                            <div id="evtOverview" className="collapse" role="tabpanel" aria-labelledby="overview_heading" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.overview.processed }}>
                                </div>
                            </div>
                        </div>
                        }
                        {
                        pageInfo?.faculty?.processed &&
                        <div className="card">
                            <div className="card-header" role="tab" id="faculty_heading">
                                <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#evtFaculty" aria-expanded="false" aria-controls="evtFaculty">
                                    Faculty
                                    </a>
                                </h5>
                            </div>
                            <div id="evtFaculty" className="collapse" role="tabpanel" aria-labelledby="faculty_heading" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.faculty.processed }}>
                                </div>
                            </div>
                        </div>
                        }
                        {
                        pageInfo?.coordinator?.processed &&
                        <div className="card">
                            <div className="card-header" role="tab" id="coordinator_heading">
                                <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#evtCoordinator" aria-expanded="false" aria-controls="evtCoordinator">
                                    Co-Ordinator
                                    </a>
                                </h5>
                            </div>
                            <div id="evtCoordinator" className="collapse" role="tabpanel" aria-labelledby="coordinator_heading" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.coordinator.processed }}>
                                </div>
                            </div>
                        </div>
                        }
                        {
                        pageInfo?.venue?.processed &&
                        <div className="card">
                            <div className="card-header" role="tab" id="venue_heading">
                                <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#evtvenue" aria-expanded="false" aria-controls="evtvenue">
                                    Venue
                                    </a>
                                </h5>
                            </div>
                            <div id="evtvenue" className="collapse" role="tabpanel" aria-labelledby="venue_heading" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.venue.processed }}>
                                </div>
                            </div>
                        </div>
                        }
                        {
                        pageInfo?.register?.processed && showRegBtn &&
                        <div className="card">
                            <div className="card-header" role="tab" id="register_heading">
                                <h5 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#evtRegister" aria-expanded="false" aria-controls="evtRegister">
                                        Register
                                    </a>
                                </h5>
                            </div>
                            <div id="evtRegister" className="collapse" role="tabpanel" aria-labelledby="register_heading" data-parent="#accordion">
                                <div className="card-body" dangerouslySetInnerHTML={{ __html: pageInfo.register.processed }}>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </section>
        {pageInfo?.relationships?.gallery?.length &&
        <Gallery data={pageInfo.relationships.gallery} />}
        <section className="section-bg section-py">
            <div className="container">
                <h2 className="section-heading">Upcoming Events</h2>
                <EventsListingSection
                    key="futureEvents"
                    additionalParams={params}
                    />
            </div>
        </section>
        <NewsLetter
            />
    </main>
    <VideoModal
        videoURL={videoURL}
        showModal={showModal}
        closeModal={closeModal}
        />
</Layout>
)
}
EventPage.propTypes = {
data: PropTypes.object.isRequired
}
export const query = graphql`
query ($eventId: String) {
    allNodeEvent(
        filter: {id: {eq: $eventId}}
        sort: {order: DESC, fields: field_event_end_date}
        ) {
      nodes {
        relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
        }
        metatags: metatag_normalized {
            attributes {
              content
              name
            }
          }
        field_keep_as_hero_event
        drupal_internal__nid
        id
        title
        field_event_type
        field_paid_event
        blurb: field_blurb
        video: field_video {
          url: input
        }
        overview: body {
          processed
        }
        faculty: field_faculty {
          processed
        }
        coordinator: field_co_ordinator {
          processed
        }
        register: field_register {
          processed
        }
        venue: field_event_venue {
          processed
        }
        paymentLink: field_payment_link {
          uri
        }
        recordedSession: field_recorded_session {
          uri
        }
        eventDate: field_event_date
        eventEndDate: field_event_end_date
        registrationDeadline: field_event_registration_deadlin
        field_image {
          alt
        }
        relationships {
          image: field_image {
            id
            uri {
              value
              url
            }
          }
          image_mobile: field_image {
            id
            uri {
              value
              url
            }
          }
          gallery: field_gallery {
            __typename
            ...GalleryItem
          }
          schedule: field_program_schedule {
            id
            uri {
              value
              url
            }
          }
          category: field_event_speciality {
            id
            name
          }
          brochure: field_brochure {
            id
            uri {
              value
              url
            }
          }
          field_breadcrumb_events {
            field_title
            field_breadcrumb_link {
              uri
              title
            }
          }
        }
      }
    }
  }
  
  fragment GalleryItem on paragraph__gallery_item {
    id
    blurb: field_blurb
    relationships {
      image: field_gallery_image {
        relationships {
          field_media_image {
            uri {
              value
              url
            }
          }
        }
      }
    }
  }
`
// metatags: metatag_normalized {
//     attributes {
//       content
//       name 
//       property

//     }
//   }
export default EventPage
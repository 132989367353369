import React, { useEffect, useState } from "react"
import Slider from "react-slick";

const Gallery = (props) => {

    const { data } = props
    const [galleryArr, setGalleryArr] = useState([])

    const settings = {
        centerMode: true,
        dots: false,
        lazyLoad: true,
        slidesToShow: 2,
        infinite: data?.length === 1 ? false : true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 3000,
        centerPadding : '0px',
        responsive : false,
        responsive: [{
           breakpoint: 800,
           settings: {
              arrows: true,
              centerMode: false,
              slidesToShow: 1,
              variableWidth: false
           }
        }, {
           breakpoint: 480,
           settings: {
              arrows: true,
              centerMode: false,
              slidesToShow: 1,
              variableWidth: false
           }
        }]
    };

    const renderImage = (data) => {
        if (data && data?.uri?.url) {
            return <img src={data.uri?.url} alt="" />
        }
        return null
    }

    useEffect(() => {
        if(data?.length) {
            let arr = []
            data.forEach(el => {
                if(el?.blurb && el?.relationships?.image?.relationships?.field_media_image?.uri?.url) {
                    arr.push(el)
                }
            })
            setGalleryArr(arr)
        }
    },[data]) 

    return (
        <>
            {galleryArr?.length 
            ? <section className="section-pt section-pb evt_section_gallery">
                <div className="container">
                    <h2>Event Gallery</h2>
                    <div className="">
                        <Slider {...settings} className="event_gallery">
                        {
                            galleryArr.map((el, index) => {
                                return (
                                    <div key={index} className="evt_item">
                                        <figure>
                                            {   (el?.relationships?.image?.relationships?.field_media_image) &&
                                                renderImage(el.relationships.image.relationships.field_media_image)
                                            }
                                            <figcaption>
                                                {el.blurb}
                                            </figcaption>
                                        </figure>
                                    </div>
                                )
                            })
                        }
                        </Slider>
                    </div>
                    {/* THUMBNAILS */}
                    <div className="slider-nav-thumbnails">
                        {
                            galleryArr.map((el, index) => {
                                return (
                                    <div key={index}>
                                        {   (el?.relationships?.image?.relationships?.field_media_image) &&
                                            renderImage(el.relationships.image.relationships.field_media_image)
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section> 
            : null}
        </>
    )
}

export default Gallery
